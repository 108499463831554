.help-title{
    font-family: Kanit, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;

}

.warning-message{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    color: #FF8903;
    margin-top: 3px;

}
.modal-address-container{
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(69, 42, 122, 0.6)
}
.modal-address-container.show-address-modal{
    visibility: visible;
    opacity: 1;
}
.address-modal{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
}
.card-circle-text{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}
.card-text{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;

}
.card-help{
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 283px;
    padding: 40px 51px 40px 52px;
    background: #292D3F;
    border-radius: 12px;
}
.free-ticket-title{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 32px;
}
.free-ticket-container{
    margin-bottom: 129px;
    z-index: 2;
}
.card-circle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 91px;
    height: 91px;
    background: #34384C;
    border-radius: 50px;
    margin-bottom: 35px;
}
.help-sup-link{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    text-decoration: underline;
    color: #FFFFFF !important;
    display: inline-block !important;
}
.help-button{
    margin-left: 20px;
    color: black;
    font-size: 16px;
    font-weight: 600;
    border: transparent;
    background-color: transparent;
}
.first-row-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px;
    margin-bottom: 35px;
}
.card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 61px;
    margin-bottom: 80px;
}
.section-title-help{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
    color: #FFFFFF;
}
.section-text-help{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    list-style: none;
    /* or 160% */

    list-style-type: decimal;
    color: #FFFFFF;

}

.tab-container {
    width: 641px;
    height: 64px;
    background: #292D3F;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    margin-top: 16px;
}

.tab-item {
    width: 212px;
    height: 44px;
    background: #292D3F;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
}

.tab-item-active {
    width: 212px;
    height: 44px;
    background: #11CABE;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
}

.tab-active-content {
    display: block;
}

.tab-content {
    display: none;
}

.tab-item-text {
    font-size: 16px;
    line-height: 22.4px;
    color: #FFFFFF;
}

.create-wallet-list-wrapper {
    margin: 24px 0 0 0;
}
.create-wallet-item{
    color: #f9f9f9;
    margin-left: 10px;
}

.create-wallet-numeric-item{
    color: #f9f9f9;
    margin-left: 10px;
    list-style-type: decimal;
}

.create-wallet-title{
    display: inherit;
    margin-bottom: 24px;
}
.create-wallet-section{
    display: flex;
    margin-top: 35px;
}
.create-wallet-list-side{
    margin-right: 43px;
}

.connect-wallet-text:not(:last-child){
    margin-bottom: 30px;
}

.connect-wallet-list > *{
    margin-left: 10px;
    color: #ffffff;
}
.wallet-list-title {
    font-family: Kanit, sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    display: inline-block;
    list-style: none;
    margin-right: 6px
}

.connect-wallet-list-container{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.wallet-list-sub-title{
    font-family: Kanit, sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    display: inline-block;
    list-style: none;
    margin-right: 6px
}
.connect-wallet-wrapper{
    display: flex;
    margin-top: 40px;
}
.connect-wallet-image-wrapper:not(:last-child){
    margin-bottom: 56px;
}
.connect-wallet-details{
    margin-right: 43px;
}
.section-list-wrapper{
    margin-bottom: 24px;
    margin-left: 14px;
}
.orange-text{
    color: #e5b074;
}
.section-pre-title-help{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 12px;
}
.win-amount{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 96px;
    color: #FFFFFF;
}
.win-amount-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 92px;
}
.win-btn-wrapper{
    margin-bottom: 92px;
    margin-top: 50px;
}
.support-section-title{
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 22px;
    color: #F4EEFF;

}
.support-section-text{
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 32px;
}
.social-icons-container{
    display: flex;
}
.social-icons-container{
    margin-top: 40px;
}
.social-icon-wrapper:not(:last-child){
    margin-right: 32px;
}
.build-info-container{
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 80px;
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    margin-left: 3px;
}

.mb {
    margin-bottom: 56px;
}

.help-page-copytext-button .flexRow {
    margin-top: 0;
}

.notification {
    border-radius: 4px;
    color: #f9f9f9;
    display: flex;
    align-items: center;
    padding: 18px;
    background-color: #fff;
    margin: 20px;
    width: 100%;
}

.notification--success {
    background-color: #09814a;
}

.notification-text {
    padding-left: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.help-page-icon-image{
    margin-left: 8px;
    cursor: pointer;
}

.card-container {
    margin-bottom: 56px;
}

.ml {
    margin-left: 24px;
}

.help-page-copytext-button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .connect-wallet-wrapper,
    /*.create-wallet-section {*/
    /*    display: block;*/
    /*}*/

    /*.create-wallet-section a{*/
    /*    display: none;*/
    /*}*/

    .connect-wallet-wrapper img,
    .connect-wallet-image-wrapper img,
    .create-wallet-section img {
        width: 100%;
        object-fit:  contain;
        margin: 25px 0;
    }

    .connect-wallet-list-container {
        display: block;
    }

    .wallet-list-title,
    .wallet-list-sub-title {
        font-size: 15px;
        line-height: 32px;
        color: #FFFFFF;
    }
    .help-page-icon-image{
        margin-left: 8px !important;
        cursor: pointer;
    }
}
.footer-help-block{
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
}
.help-page-image{
    max-width: unset;
}

.help-page-image-container{
    margin-left: 43px;
}

.section-text-help-crypt{
    font-family: Kanit, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #F9F9F9;
}

.marginDiv {
    margin-top: 16px;
    margin-bottom: 16px;
}

.mt {
    margin-top: 20px;
}

.section-text-help-orange {
    color: #e5b074;
    margin-left: 4px;
}

@media (max-width: 1024px){
    .social-icon-wrapper:not(:last-child){
        margin-right: 16px;
    }

    .support-section-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
    }
    .address-modal{
        width: 327px;
    }
    .address-modal-button{
        font-family: Kanit, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.6000000238418579px;
        text-align: center;
        width: 327px !important;
    }
    /* daniil */
    .help-page-image-container{
        margin-left: 0px;
    }

}
@media (max-width: 600px) {
    .tab-container {
        width: 232px;
        height: 179px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0 10px;
        margin-top: 24px;
    }
    .tab-item-active {
        height: 44px;
        width: 100%;
    }
    .create-wallet-list-side {
        margin-right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .create-wallet-list {
        padding-left: 8px;
        list-style-position: outside;
    }
    .help-page-image-container {
        margin-left: 0px;
        margin-top: 15px;
    }
    .help-page-image{
        margin-bottom: 56px !important;
    }
    .connect-wallet-details {
        margin-right: 0px;
    }
    .section-title-help, .create-wallet-title {
        align-self: flex-start;
    }
    .tab-content-container {
        width: 100%;
    }
    .create-wallet-section a{
        display: block;
    }
    .mb {
        margin-top: 16px;
        margin-bottom: 0px;
        align-self: center;
    }
    .help-page-icon-image {
        height: 16px;
        width: 10% !important;
    }
    .section-text-help-crypt {
        width: 100%;
        text-align: left;
    }
    .first-row-card {
        margin-bottom: 0;
    }
    .section-text-help-crypt {
        word-break: break-all;
    }
    .flexRow {
        margin-left: 0;
        margin-top: 0;
    }
    .help-page-copytext-button {
        padding-left: 0;
    }
    .ml, .mt{
        display: none;
    }
    .connect-wallet-details {
        margin-top: 16px;
    }
    .section-text-help-orange {
        word-break: break-all;
    }
    .section-text-help {
        word-break: none;
    }
    .dfjcc {
        display: flex;
        justify-content: center;
    }
    .help-page-icon-image{
        margin-left: 0px !important;
    }

}
