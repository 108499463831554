.balls-colorize_0 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #ffffff 0%, #f9f9f9 12.08%, #f9f9f9 32.1%);
}
.balls-colorize_1 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #e4d8ff 0%, #cfbff4 12.08%, #c2aff0 32.1%);
}
.balls-colorize_2 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #ffd675 0%, #ffc435 12.08%, #ffb400 32.1%);
}
.balls-colorize_3 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #f8fff5 0%, #edfbe8 12.08%, #dff2d8 32.1%);
}
.balls-colorize_4 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #fdc5f5 0%, #f0a6e6 12.08%, #e988db 32.1%);
}
.balls-colorize_5 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #aaf4cc 0%, #8ce8b6 12.08%, #69dc9e 32.1%);
}
.balls-colorize_6 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #bceaff 0%, #97d8f5 12.08%, #78cef7 32.1%);
}
.balls-colorize_7 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #9f91ef 0%, #8b7aee 12.08%, #7765e3 32.1%);
}
.balls-colorize_8 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #ffa499 0%, #fb8779 12.08%, #f87060 32.1%);
}
.balls-colorize_9 {
  background: radial-gradient(79.76% 79.76% at 41.67% 20.24%, #fff0f7 0%, #fadce9 12.08%, #f1c8db 32.1%);
}

.poollotto-card-block {
  position: relative;
  display: flex;
  align-items: center;
  width: 370px;
  background-color: #292d3f;
  height: 128px;
  border-radius: 128px;
  padding-right: calc(128px / 2);
  padding-left: calc(128px + 40px);
}

.poollotto-card-block__round {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: #40455c;
}

.poollotto-card-block__round [data-block='1'] path {
  fill: white;
}
.poollotto-card-block__round [data-block='1']:hover path {
  fill: url(#paint0_linear_1322_1457);
}
.poollotto-card-block__round [data-block='2'] path {
  filter: grayscale(100%);
}
.poollotto-card-block__round [data-block='2']:hover path {
  filter: grayscale(0);
}

.poollotto-card-block__content {
  display: flex;
  flex-direction: column;
}
.poollotto-card-block__content a {
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #fff;
}
.poollotto-card-block__content a > svg {
  margin-left: 8px;
  width: 16px;
}

.poollotto-card-block__content span {
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #e5b074;
}

/* .mobile-banner-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  display: flex;
  justify-content: center;
}

.banner-wrapper {
  padding-top: 60px;
  justify-content: flex-end;
  display: flex;
  margin-right: 66px;
}

.banner {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: 569px;
  height: 114px;
  background: url('/src/assets/images/banner/banner-desktop.png') no-repeat 85%,
    linear-gradient(266.69deg, #b2242d -7.85%, #273884 106.89%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  border-radius: 26px;
  padding-left: 24px;
  padding-right: 220px;
  color: #fff;
  z-index: 1;
}
.banner__text {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}
.banner__text span {
  color: #fdcf50;
}
.banner.is-mobile {
  max-width: 335px;
  padding-right: initial;
  width: 100%;
}
.banner.is-mobile .banner__text {
  font-size: 18px;
  line-height: 24px;
}
.banner {
	position: absolute;
	top: 27px;
	right: 66px;
	width: 569px;
	height: 114px;
	z-index: 999999;
  }
  
.banner.is-mobile {
	position: relative;
	top: initial;
	right: initial;
	max-width: 335px;
	width: 100%;
  }  
*/

.mt {
  margin-top: -120px;
}

.help-title {
  font-family: Kanit, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.warning-message {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 12px;
  color: #ff8903;
  margin-top: 3px;
}
.modal-address-container {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(69, 42, 122, 0.6);
}
.modal-address-container.show-address-modal {
  visibility: visible;
  opacity: 1;
}
.address-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;
}
.card-circle-text {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.card-text {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
}
.card-help {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 283px;
  padding: 40px 51px 40px 52px;
  background: #292d3f;
  border-radius: 12px;
}
.free-ticket-title {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 32px;
}
.free-ticket-container {
  margin-bottom: 129px;
  z-index: 2;
}
.card-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 91px;
  height: 91px;
  background: #34384c;
  border-radius: 50px;
  margin-bottom: 35px;
}
.help-sup-link {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-decoration: underline;
  color: #ffffff !important;
  display: inline-block !important;
}
.help-button {
  margin-left: 20px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  border: transparent;
  background-color: transparent;
}
.first-row-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
  margin-bottom: 35px;
}
.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 61px;
  margin-bottom: 80px;
}
.section-title-help {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
}
.section-text-help {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  list-style: none;
  /* or 160% */

  list-style-type: decimal;
  color: #ffffff;
}

.tab-container {
  width: 641px;
  height: 64px;
  /* background: #292D3F; */
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  margin-top: 16px;
  background: #34384c;
}

.tab-item {
  width: 212px;
  height: 44px;
  background: #292d3f;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: #34384c;
}

.tab-item-active {
  width: 212px;
  height: 44px;
  background: #11cabe;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.tab-active-content {
  display: block;
}

.tab-content {
  display: none;
}

.tab-item-text {
  font-size: 16px;
  line-height: 22.4px;
  color: #a5accf;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.tab-item-text-active {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  color: #ffffff;
}

.create-wallet-list-wrapper {
  margin: 24px 0 0 0;
}
.create-wallet-item {
  color: #f9f9f9;
  margin-left: 10px;
}

.create-wallet-numeric-item {
  color: #f9f9f9;
  margin-left: 10px;
  list-style-type: decimal;
}

.create-wallet-title {
  display: inherit;
  margin-bottom: 24px;
}
.create-wallet-section {
  display: flex;
  /* margin-top: 35px; */
}
/* .create-wallet-list-side{
  margin-right: 43px;
} */

.connect-wallet-text:not(:last-child) {
  margin-bottom: 30px;
}

.connect-wallet-list > * {
  margin-left: 10px;
  color: #ffffff;
}
.wallet-list-title {
  font-family: Kanit, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  display: inline-block;
  list-style: none;
  margin-right: 6px;
}

.connect-wallet-list-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.wallet-list-sub-title {
  font-family: Kanit, sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
  display: inline-block;
  list-style: none;
  margin-right: 6px;
}
.connect-wallet-wrapper {
  display: flex;
  margin-top: 40px;
}
.connect-wallet-image-wrapper:not(:last-child) {
  margin-bottom: 56px;
}
.connect-wallet-details {
  margin-right: 43px;
}
.section-list-wrapper {
  margin-bottom: 24px;
  margin-left: 14px;
}
.orange-text {
  color: #e5b074;
}
.section-pre-title-help {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 12px;
}
.win-amount {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 96px;
  color: #ffffff;
}
.win-amount-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 92px;
}
.win-btn-wrapper {
  margin-bottom: 92px;
  margin-top: 50px;
}
.support-section-title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 22px;
  color: #f4eeff;
}
.support-section-text {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 32px;
}
.social-icons-container {
  display: flex;
}
.social-icons-container {
  margin-top: 40px;
}
.social-icon-wrapper:not(:last-child) {
  margin-right: 32px;
}
.build-info-container {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 80px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-left: 3px;
}

.flexRowMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-left: 3px;
}

.mb {
  margin-bottom: 56px;
}

.help-page-copytext-button .flexRow {
  margin-top: 0;
}

.footer-help-block {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
}
.help-page-image {
  max-width: fit-content;
}

.help-page-image-container-mt {
  margin-top: 84px;
}

.section-text-help-crypt {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #f9f9f9;
}

.marginDiv {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mt {
  margin-top: 20px;
}

.section-text-help-orange {
  color: #e5b074;
  margin-left: 4px;
  font-size: 14px;
}

.notification {
  border-radius: 4px;
  color: #f9f9f9;
  display: flex;
  align-items: center;
  padding: 18px;
  background-color: #fff;
  margin: 20px;
  width: 100%;
}

.notification--success {
  background-color: #09814a;
}

.notification-text {
  padding-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.help-page-icon-image {
  margin-left: 8px;
  cursor: pointer;
}

.card-container {
  margin-bottom: 56px;
}

.ml {
  margin-left: 24px;
}

.help-page-copytext-button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.dropdown-container {
  margin-top: 40px;
  background: #292d3f;
  border-radius: 40px;
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  padding: 24px 24px 24px 24px;
  /* padding: 24px 24px 40px 24px; */
}

.mt80 {
  margin-top: 80px;
}

.dropdown-label-container {
  display: flex;
  align-items: center;
}

.dropdown-circle {
  border-radius: 50px;
  border: 1px solid #ffffff;
  height: 62px;
  width: 62px;
  margin-right: 32px;
}

.dropdown-description {
  display: flex;
  flex-direction: column;
  height: 62px;
  margin-top: 4px;
}

.title-text {
  margin-bottom: 8px;
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #a5accf;
}

.subtitle-text {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
}

.dropdown-button {
  padding: 20px 68px;
  width: 276px;
  height: 60px;
  border: 1px solid 'linear-gradient(to left, #922C32, #B67112)';
  border-radius: 100px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-button-text {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
}

.translateY {
  transform: translateY(-12px);
}

.dropdown-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdown-button-container {
  display: flex;
  align-items: center;
}

.dropdown-arrow-circle {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  margin-left: 32px;
  cursor: pointer;
}

.dropdown-content-show {
  display: flex;
  flex-direction: row;
  padding-top: 34px;
  padding-left: 8px;
}

.dropdown-content-hide {
  display: none;
  flex-direction: row;
  padding-top: 34px;
  padding-left: 8px;
}

.section-split-help {
  display: flex;
  flex-direction: row;
  margin-right: -100px !important;
  margin: auto;
}

.auto,
.manually {
  width: 354px;
  border-radius: 14px;
  background: #34384c;
  padding: 20px;
  min-height: 215.42px;
}

.auto {
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card-label {
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 9.43px;
}

.cardlabelAuto {
  position: absolute;
  padding: inherit;
  left: 0;
  top: 0;
  font-family: Kanit, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.mt0 {
  margin-top: 0 !important;
}

.ml32 {
  margin-left: 32px;
  margin-top: 0px;
}

@media (max-width: 1200px) {
  .create-wallet-section {
    flex-wrap: wrap;
  }

  .dropdown-content-show {
    flex-wrap: wrap;
  }

  .help-page-image-container,
  .help-page-image-container-mt {
    margin: auto;
  }
  .flexRowMobile {
    justify-content: center;
  }
  .dfjcc {
    display: flex;
    justify-content: center;
  }
  .dropdown-content-show-center {
    width: 100%;
  }
  .help-page-image {
    margin-top: 25px;
  }
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .create-wallet-list-side {
    width: 100%;
  }
  .dropdown-button-container {
    display: flex;
    justify-content: center;
  }
  .dropdown-button-container {
    flex-direction: column;
  }
  .dropdown-header {
    display: block;
  }
  .dropdown-arrow-circle {
    margin-left: auto;
  }
  .ml32 {
    margin-left: 0px;
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .connect-wallet-wrapper,
  .create-wallet-section {
    display: block;
  }
  .connect-wallet-wrapper img,
  .connect-wallet-image-wrapper img,
  .create-wallet-section img {
    width: 100%;
    object-fit: contain;
    margin-bottom: 25px;
  }

  .connect-wallet-list-container {
    display: block;
  }

  .wallet-list-title,
  .wallet-list-sub-title {
    font-size: 15px;
    line-height: 32px;
    color: #ffffff;
  }
  .help-page-icon-image {
    margin-left: 8px !important;
    cursor: pointer;
  }
  .help-page-image-container,
  .help-page-image-container-mt {
    justify-content: center;
    display: flex;
  }
  .help-page-image {
    height: fit-content;
  }
  .flexRowMobile {
    justify-content: center;
  }
  .dfjcc {
    display: flex;
    justify-content: center;
  }
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .social-icon-wrapper:not(:last-child) {
    margin-right: 16px;
  }

  .support-section-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
  }
  .address-modal {
    width: 327px;
  }
  .address-modal-button {
    font-family: Kanit, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.6000000238418579px;
    text-align: center;
    width: 327px !important;
  }
  /* .help-page-image-container{
      margin-left: 0px;
  } */
  .create-wallet-list-side {
    width: 100%;
  }
  .dropdown-button-container {
    display: flex;
    justify-content: center;
  }
  .dropdown-button-container {
    flex-direction: column;
  }
  .dropdown-header {
    display: block;
  }
  .dropdown-arrow-circle {
    margin-left: auto;
  }
  .ml32 {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (max-width: 768px) {
  .tab-container {
    width: 100%;
    height: 179px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }
  .tab-item-active {
    height: 44px;
    width: 100%;
  }
  .create-wallet-list-side {
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .create-wallet-list {
    padding-left: 8px;
    list-style-position: outside;
  }
  .help-page-image-container {
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
  .help-page-image {
    margin-bottom: 56px !important;
  }
  .connect-wallet-details {
    margin-right: 0px;
  }
  .section-title-help,
  .create-wallet-title {
    align-self: flex-start;
  }
  .tab-content-container {
    width: 100%;
  }
  .mb {
    margin-top: 16px;
    margin-bottom: 0px;
    align-self: center;
  }
  .help-page-icon-image {
    height: 16px;
    width: 10% !important;
  }
  .section-text-help-crypt {
    width: 100%;
    text-align: left;
  }
  .first-row-card {
    margin-bottom: 0;
  }
  .section-text-help-crypt {
    word-break: break-all;
  }
  .flexRow {
    margin-left: 0;
    margin-top: 0;
  }
  .help-page-copytext-button {
    padding-left: 0;
  }
  .ml,
  .mt {
    display: none;
  }
  .connect-wallet-details {
    margin-top: 16px;
  }
  .section-text-help-orange {
    word-break: break-all;
  }
  .section-text-help {
    word-break: none;
  }
  .dfjcc {
    display: flex;
    justify-content: center;
  }
  .help-page-icon-image {
    margin-left: 0px !important;
  }
  .dropdown-header {
    display: block;
  }
  .subtitle-text {
    font-size: 18px;
  }
  .dropdown-content-show {
    padding-left: 8px;
  }
  .section-split-help {
    display: block;
  }
  .dropdown-circle {
    height: 32px;
    width: 32px;
    margin-right: 16px;
  }
  .dropdown-button-container {
    margin-top: 24px;
  }
  /* .dropdown-label-container{
      justify-content: space-between;
  } */

  .dropdown-arrow-circle {
    margin-left: auto;
  }
  .auto,
  .manually {
    width: 323px;
  }
  .auto {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-right: 0;
  }
  .manually {
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .dropdown-content-show {
    display: block;
    padding: 0;
    padding-top: 40px;
  }
  .dropdown-button {
    margin-top: 24px;
    /* margin-bottom: 16px; */
    margin-left: 0;
  }
  .dropdown-container {
    padding: 16px;
  }
  .dropdown-container:last-child {
    margin-bottom: 40px;
  }
  .dropdown-button-container {
    margin-top: 0;
  }
  .help-page-image {
    width: 100%;
    object-fit: contain;
    margin-bottom: 25px;
  }
  .help-page-image-container-mt {
    margin-top: 0;
  }
  .help-page-image {
    margin-bottom: 0px !important;
  }
  .dropdown-button-container {
    display: flex;
    justify-content: center;
  }
  .tab-item-active {
    width: 295.16px;
  }
  /* .create-wallet-list {
      padding-left: 0;
  } */
  .flexRowMobile {
    flex-direction: column;
  }
  .section-split-help {
    margin-right: 0px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: -4px;
  }
  .dropdown-button-container {
    flex-direction: column;
  }
  .ml32 {
    margin-left: 0px;
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .banner-wrapper {
    padding-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .mt {
    margin-top: 25px;
  }
  .powered {
    transform: translateY(0px);
  }
  .banner-wrapper {
    padding-top: 27px;
  }
}
